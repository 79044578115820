<template>
    <div>
        <el-dialog
            :title="`${formData.id ? '编辑':'新增'}套餐`"
            :visible.sync="visible"
            width="870px"
            :close-on-click-modal="false"
            :append-to-body="true"
            @close="close"
            center
        >
            <el-form :inline="false" :model="formData" class="formData" ref="formData" :rules="rules" label-width="140px" v-loading="loading">
                <el-form-item label="套餐名称：" prop="name">
                    <el-input v-model="formData.name" style="width:600px" maxlength="8" placeholder="请输入套餐名称" show-word-limit />
                </el-form-item>
                <el-form-item label="套餐编码：" >
                    <el-input v-model="formData.code" style="width:600px" disabled maxlength="12" placeholder="请输入套餐编码" />
                </el-form-item>
                <el-form-item label="套餐备注：" prop="remark" >
                    <el-input v-model="formData.remark" style="width:600px" show-word-limit maxlength="12" placeholder="请输入套餐备注" />
                </el-form-item>
                <el-form-item label="套餐周期：" prop="rentCycle">
                    <el-select v-model="formData.rentCycle" placeholder="" style="width:600px">
                        <el-option label="包年" :value="1" />
                    </el-select>
                </el-form-item>
                <el-form-item label="套餐供货价格：" prop="purchasePrice" >
                    <el-input v-model="formData.purchasePrice" @input="numberFn('purchasePrice')" style="width:600px" maxlength="11" placeholder="请输入套餐价格" />元
                </el-form-item>
                <el-form-item label="价格增幅比例：" prop="priceAdjustRatio" >
                    <el-input v-model="formData.priceAdjustRatio" @input="numberFn('priceAdjustRatio')" style="width:600px" maxlength="11" placeholder="请输入10%-30%的增幅比例" />%
                </el-form-item>
                <el-form-item label="套餐实际价格：" prop="price" >
                    <el-input v-model="formData.price" disabled style="width:600px" maxlength="11" placeholder="自动计算" />元
                </el-form-item>
                <el-form-item label="序号：" prop="orderNum" >
                    <el-input v-model="formData.orderNum" oninput="value=value.replace(/^(0+)|[^\d]+/g,'')" style="width:600px" placeholder="序号唯一,序号越大显示在越前面" />
                </el-form-item>
                <el-form-item label="特权说明：" prop="specialRemark" >
                    <el-input type="textarea" row="3" v-model="formData.specialRemark" style="width:600px" maxlength="500" show-word-limit  placeholder="请输入特权说明" />
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="close">取 消</el-button>
                <el-button type="primary" @click="submit">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import * as formRules from '@/assets/js/formRules'

import { URL } from "../../../../config/url.supplier";

export default {
    name: '',
    props: {
		dialogVisible:{
			type: Boolean,
			default: false
		},
		currentRow:{
			type:Object,
			default: () => {}
		}
	},
    data() {
        return {
            visible: false,
            loading: false,
            formData: {
                name: '',
                code: '',
                remark: '',
                rentCycle: '',
                purchasePrice: '',
                priceAdjustRatio: '',
                price: '',
                orderNum: '',
                specialRemark: '',
            },
            formRules,
            rules: {
                name: [
                    { required: true, message: '请输入套餐名称', trigger: 'blur' },
                ],
                code: [
                    { required: true, message: '请输入编码,且编码唯一,不可修改', trigger: 'blur' },
                ],
                remark: [
                    { required: true, message: '用于前端对用户呈现', trigger: 'blur' },
                ],
                rentCycle: [
                    { required: true, message: '请选择套餐周期', trigger: 'change' }
                ],
                purchasePrice: [
                    { required: true, message: '请输入套餐价格', trigger: 'blur' }
                ],
                priceAdjustRatio: [
                    { required: true, message: '请输入增幅比例', trigger: 'blur' }
                ],
                price: [
                    { required: true, message: '请输入套餐价格', trigger: 'blur' }
                ],
                orderNum: [
                    { required: true, message: '序号唯一,序号越大显示在越前面', trigger: 'blur' }
                ],
                specialRemark: [
                    { required: true, message: '请输入特权说明', trigger: 'blur' },
                ],
            },
            options: [],
        }
    },
    methods:  {
        numberFn(p){
            this.formData[p] = this.formData[p]
            .replace(/[^\-\d.]/g, '') // 只能输入   数字 小数点 -
            .replace(/\-{2,}/g, "-") // 只能出现一次-
            .replace(/^0+(\d)/, '$1') // 如果第一位是 0 就替换成后面的数字
            .replace(/^\./, '0.') // 如果第一位是 . 就 替换成  0.
            .match(/^[\d\-]*(\.?\d{0,2})/g)[0] || '' // 开头只能允许数字或者 -

            if (this.formData.purchasePrice && this.formData.priceAdjustRatio) {
                this.formData.price = (((this.formData.priceAdjustRatio / 100) + 1) * this.formData.purchasePrice).toFixed(2)
            }
        },
        close() {
            this.$emit('closeAdd')
        },

        submit() {
            this.$refs.formData.validate((valid) => {
                if(!valid) { return }
                if(this.formData.priceAdjustRatio < 10 || this.formData.priceAdjustRatio > 30) {
                    this.$message.error('请输入10%-30%的增幅比例');
                    return;
                }
                let url = !this.formData.id ? URL.farm.farmRentPackageSave : URL.farm.farmRentPackageUpdate;
                this.axios[!this.formData.id ? 'post' : 'put'](url, this.formData).then(res=>{
                    if(res.code === '0'){
                        this.$message.success('操作成功');
                        this.close();
                    }else{
                        this.$message.error(res.msg);
                    }
                })
            })

        }

    },

     watch:{
        currentRow: {
            handler: function(oldV, newV) {
                const { name, code, remark, rentCycle, purchasePrice, priceAdjustRatio, price, orderNum, specialRemark, id } = oldV;
                this.formData = {
                    name,
                    code,
                    remark,
                    rentCycle,
                    purchasePrice,
                    priceAdjustRatio,
                    price,
                    orderNum,
                    specialRemark,
                    id
                }
            },
            deep: true
        },
        dialogVisible: function(val) {
            this.visible = val;
        }
    },

    mounted() {

    }

}
</script>

<style lang="less" scoped>
.formData{
	padding:0 20px;
	h4{
		text-align: center;
	}
	.quillBox{
		width: 600px;
		height: 400px;
		/deep/.ql-formats{
			line-height:0;
		}
		/deep/.ql-toolbar{
			line-height:0;
		}
		/deep/.viewOnly{
			height: 0px;
			overflow: hidden;
			border-top: 0;
			padding: 0px;
		}
	}
	.upload-item-image{
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
	.marginL10{
		margin-left: 10px;
	}
	h4{
		margin:15px 0;
	}
}
.avatar-uploader-quill {
	display: none;
}

.videoBox{
	position: relative;
	/deep/ .el-upload--picture-card {
		width: 100px;
		height: 100px;
	}
	/deep/ .avatar-uploader-icon {
		position: absolute;
		top: 50%;
		left: 11%;
		margin-left: -35px;
		margin-top: -14px;
	}
}

.videoTips {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: .5;
    background: #000;
    z-index: 2009 !important;
}

/deep/ .el-form-item__content {
    min-width: 140px !important;
}

.quillBox {
	/deep/ .ql-container .ql-video {
		height: 220px;
	}
}
</style>
