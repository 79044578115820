<template>
    <el-dialog
        title="关联服务"
        :visible.sync="visible"
        width="700px"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        center
        @close="close"
    >
        <div class="item" v-if="seed.length > 0">
            <div class="name"><span style="color: red;">*</span>种子:</div>
            <div class="service">{{ seed[0].name }}</div>
            <div class="free">种植单位免费次数:</div>
            <div class="used">
                <el-input
                    v-model="seed[0].count"
                    placeholder="请输入整数"
                    :disabled="seed[0].checked"
                    oninput="value=value.replace(/\D/g,'')"
                ></el-input>
                <div style="margin-left: 10px;">次</div>
            </div>
            <div class="residue">
                <el-checkbox v-model="seed[0].checked" :label="-100"
                    >不限次</el-checkbox
                >
            </div>
        </div>
        <div class="item" v-for="(item, i) in tableData" :key="i">
            <div class="name">
                <span style="color: red;" v-if="i == 0">*</span
                >{{ i == 0 ? "服务:" : "" }}
            </div>
            <div class="service">{{ item.name }}</div>
            <div class="free">种植单位免费次数:</div>
            <div class="used">
                <el-input
                    v-model="item.count"
                    :disabled="item.checked"
                    placeholder="请输入整数"
                    oninput="value=value.replace(/\D/g,'')"
                ></el-input>
                <div style="margin-left: 10px;">次</div>
            </div>
            <div class="residue">
                <el-checkbox v-model="item.checked" :label="-100"
                    >不限次</el-checkbox
                >
            </div>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button @click="close">取 消</el-button>
            <el-button type="primary" @click="save">确 定</el-button>
        </span>
    </el-dialog>
</template>

<script>
import { URL } from "../../../../config/url.supplier";

export default {
    name: "relevanceService",
    props: {
        dialogVisible: {
            type: Boolean,
            default: false
        },
        currentRow: {
            type: Object,
            default: () => {}
        }
    },
    data() {
        return {
            visible: false,
            loading: false,
            index: null,
            tableData: [],
            seed: []
        };
    },
    methods: {
        getList() {
            this.axios
                .get(
                    `${URL.farm.farmRentpackagerelateserviceListByPackageId}${this.currentRow.id}`
                )
                .then(res => {
                    if (res.code === "0") {
                        this.tableData = res.data.filter(
                            item => item.type != 1
                        );
                        this.seed = res.data.filter(item => item.type == 1);

                        this.seed.forEach(v => {
                            if (v.limited == 0) {
                                this.$set(v, "checked", true);
                                v.count = 0;
                            } else {
                                this.$set(v, "checked", false);
                            }
                            // if (v.count == -100) {
                            //     v.checked = true;
                            //     v.count = "";
                            // }
                        });
                        this.tableData.forEach(v => {
                            if (v.limited == 0) {
                                this.$set(v, "checked", true);
                                v.count = 0;
                            } else {
                                this.$set(v, "checked", false);
                            }
                            // if (v.count == -100) {
                            //     v.checked = true;
                            //     v.count = "";
                            // }
                        });
                    } else {
                        this.$message.error(res.msg);
                    }
                });
        },

        save() {
            if (this.seed[0].count === "" && this.seed[0].checked == false) {
                this.$message.error("请输入或者勾选");
                return;
            }
            for (let index = 0; index < this.tableData.length; index++) {
                if (
                    this.tableData[index].count === "" &&
                    this.tableData[index].checked == false
                ) {
                    this.$message.error("请输入或者勾选");
                    return;
                }
            }
            this.loading = true;
            let url = URL.farm.farmRentpackagerelateserviceSaveOrUpdate;
            this.seed.forEach(v => {
                if (v.checked) {
                    v.count = 0;
                    v.limited = 0;
                } else {
                    v.limited = 1;
                }
            });
            this.tableData.forEach(v => {
                if (v.checked) {
                    v.count = 0;
                    v.limited = 0;
                } else {
                    v.limited = 1;
                }
            });
            const params = [...this.seed, ...this.tableData];

            this.axios.post(url, params).then(res => {
                if (res.code === "0") {
                    this.$message.success("操作成功");
                    this.close();
                } else {
                    this.$message.error(res.msg);
                }
                this.loading = false;
            });
        },

        close() {
            this.$emit("closeDialog");
        }
    },

    watch: {
        currentRow: {
            handler: function(oldV, newV) {
                // console.log(newV, "3333");
            },
            deep: true
        },
        dialogVisible: function(val) {
            this.visible = val;
            this.getList();
        }
    },

    mounted() {
        // this.getList();
    }
};
</script>

<style lang="less" scoped>
.item {
    display: flex;
    margin-bottom: 20px;
    align-items: center;

    .name {
        width: 50px;
    }

    .service {
        width: 100px;
    }

    .free {
        width: 150px;
    }

    .used {
        display: flex;
        align-items: center;
        width: 170px;
    }

    .residue {
        width: 100px;
        padding-left: 20px;
    }
}
</style>
