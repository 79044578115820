<template>
    <div class="pages" v-loading="loading">
        <table-height-auto>
            <template slot="before">
                <el-form :inline="true" class="demo-form-inline">
                    <el-form-item label="套餐名称:">
                        <el-input v-model="search.name" clearable placeholder="模糊搜索" style="width: 160px;"></el-input>
                    </el-form-item>
                    <el-form-item label="套餐编码:">
                        <el-input v-model="search.code" clearable placeholder="精确搜索" style="width: 160px;"></el-input>
                    </el-form-item>
                    <el-form-item label="周期:">
                        <el-select v-model="search.rentCycle" placeholder="" style="width: 160px;">
                            <el-option
                                v-for="item in serviceTypeList"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="状态:">
                        <el-select v-model="search.enable" placeholder="" style="width: 160px;">
                            <el-option label="全部" value="" />
                            <el-option label="禁用" value="0" />
                            <el-option label="启用" value="1" />
                        </el-select>
                    </el-form-item>

                    <el-form-item>
                        <el-button type="primary" size="medium" @click="getList">查询</el-button>
                        <el-button type="primary" size="medium" @click="reset">重置</el-button>
                        <!-- <el-button type="primary" size="medium" @click="relevanceService({})">绑定服务</el-button>
                        <el-button size="mini"  type="primary" @click="check({})">查看</el-button> -->
                    </el-form-item>

                </el-form>
            </template>

            <template v-slot:table="row">
                <el-button type="primary" size="medium" @click="edit({})">新增套餐</el-button>
                <el-table v-if="row.height"  :height="row.heightText"  :data="tableData" style="width: 100%">
                    <el-table-column type="index" width="50" label="序号" />
                    <el-table-column prop="name" label="套餐名称" width="140" />
                    <el-table-column prop="code" label="套餐编码" width="130" align="center" />

                    <el-table-column prop="rentCycle" label="周期" show-overflow-tooltip >
                        <template #default="scope">
                            {{ scope.row.rentCycle == 1 ? '包年' : ''}}
                        </template>
                    </el-table-column>
                    <el-table-column prop="price" label="套餐实际价格">
                        <template #default="scope">
                        ￥{{ scope.row.price }}
                        </template>
                    </el-table-column>
                    <el-table-column prop="remark" label="套餐备注" width="160" />
                    <el-table-column prop="enable" label="状态" width="140">
                        <template #default="scope">{{ scope.row.enable == 0 ? '禁用': scope.row.enable == 1 ? '启用':''}}</template>
                    </el-table-column>
                    <el-table-column prop="orderNum" label="排序" width="140" />
                    <el-table-column prop="name" label="套餐服务" width="140" >
                        <template #default="scope">
                            <span v-if="scope.row.name" @click="check(scope.row)" style="cursor: pointer;">查看</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="createTime" label="添加时间" width="160" />
                    <el-table-column prop="date" label="操作" width="310" align="center">
                        <template #default="scope">
                            <el-button type="primary" size="mini" @click="onOff(scope.row, 0)" v-if="scope.row.enable == 1">禁用</el-button>
                            <el-button type="primary" size="mini" @click="onOff(scope.row, 1)" v-if="scope.row.enable == 0">启用</el-button>
                            <el-button type="primary" size="mini" @click="relevanceService(scope.row)">绑定服务</el-button>
                            <el-button type="primary" size="mini" @click="edit(scope.row)">编辑</el-button>
                            <el-button type="danger" size="mini" @click="del(scope.row)">删除</el-button>
                        </template>
                    </el-table-column>

                </el-table>
            </template>

            <!-- <template slot="after">
                <div class="paging-row">
                    <el-pagination
                        v-if="total"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :current-page="currentPage"
                        :page-sizes="[5, 10, 15, 20]"
                        :page-size="pageSize"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="total">
                    </el-pagination>
                </div>
            </template> -->
        </table-height-auto>
        <editPackage @closeAdd="closeAdd" :dialogVisible="editDialogVisible" :currentRow="currentRow" />
        <relevanceService @closeDialog="closeRelevanceService" :dialogVisible="relevanceDialogVisible" :currentRow="currentRow" />
        <packageService @closeCheck="closeCheck" :dialogVisible="checkDialogVisible" :currentRow="currentRow" :packageManage='1' />
    </div>
</template>

<script>
import editPackage from './components/editPackage.vue';
import relevanceService from './components/relevanceService.vue'
import packageService from "./components/packageService.vue";

import {URL} from '../../../config'

export default {
  name: 'packageManage',
  components: {
    editPackage,
    relevanceService,
    packageService,
  },
  data() {
    return {
        search: {
            code: '',
            enable: '',
            name: '',
            rentCycle: ''
        },
        loading: false,
        tableData: [],
        currentPage: 1,
        total: 0,
        pageSize: 999,
        serviceTypeList: [
            { label: "全部", value: '' },
            { label: "包年", value: 1 },
        ],
        editDialogVisible: false,
        relevanceDialogVisible: false,
        currentRow: {},
        checkDialogVisible: false,
    }
  },
  methods: {
    getList() {
        this.axios.post(URL.farm.farmRentPackagePage, {...this.search, pageNo: this.currentPage, pageSize: this.pageSize }).then(res=>{
            console.log(res);
          if(res.code === '0'){
            this.tableData = res.data.records;
            this.total = res.data.total;
          }else{
            this.$message.error(res.msg);
          }
       })
    },

    onOff(row, enable) {
        this.$confirm(`是否切换状态为${enable==0?'禁用':'启用'},${enable==0?'禁用':'启用'}的套餐${enable==0?'不会':'会'}在APP端显示`, `${enable==0?'禁用':'启用'}提示`, {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
        }).then(() => {
            this.axios.post(URL.farm.farmRentPackageEnableOnDisable, { enable, id: row.id }).then(res=>{
                if(res.code === '0'){
                    this.getList();
                    this.$message.success('操作成功');
                }else{
                    this.$message.error(res.msg);
                }
            })
        }).catch(() => {
            this.$message({
                type: 'info',
                message: '已取消'
            });
        });
    },

    del(row) {
        this.$confirm('删除后APP端将不再展示该套餐，是否确认删除？', '删除提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
        }).then(() => {
            this.axios.delete(`${URL.farm.farmRentPackageDelete}${row.id}`).then(res=>{
                if(res.code === '0'){
                    this.$message({
                        type: 'success',
                        message: '删除成功!'
                    });
                    this.getList();
                }else{
                    this.$message.error(res.msg);
                }
            })
        }).catch(() => {
            this.$message({
                type: 'info',
                message: '已取消删除'
            });
        });
    },

    edit(row) {
        this.editDialogVisible = true;
        this.currentRow = row;
    },

    closeAdd() {
      this.editDialogVisible = false;
      this.getList();
    },

    relevanceService(row) {
        this.relevanceDialogVisible = true;
        this.currentRow = row;
    },

    closeRelevanceService() {
      this.relevanceDialogVisible = false;
      this.getList();
    },

    check (row) {
        this.checkDialogVisible = true;
        this.currentRow = row;
    },

    closeCheck() {
      this.checkDialogVisible = false;
    },

    reset () {
        this.search = {
            code: '',
            enable: '',
            name: ''
        }
        this.getList()
    },

    handleSizeChange(val,type){
        this.pageSize = val;
        this.currentPage = 1;
        this.getList()
    },

    handleCurrentChange(val,type) {
        this.currentPage = val;
        this.getList();
    },

  },
  mounted() {
    this.getList();
  },

}
</script>

<style lang="less" scoped>
.pages {
    .search {
        display: flex;
        justify-content: space-between;

    }

}

.avatar {
    width: 60px;
    height: 60px;
    border-radius: 10px;
}
</style>
