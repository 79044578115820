<template>
    <el-dialog
        title="套餐服务"
        :visible.sync="visible"
        width="650px"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        center
        @close="close"
    >
        <div v-if="seed.length > 0 || tableData.length > 0">
            <div class="item" v-if="seed.length > 0">
                <div class="name" >种子:</div>
                <div class="service">{{ packageManage == 1 ? seed[0].name : seed[0].serviceTypeName}}</div>
                <div class="free" v-if="packageManage == 1">种植单位免费次数: {{ seed[0].limited === 0 ? '不限': seed[0].count }} 次 </div>
                <div class="free" v-if="packageManage != 1">种植单位免费次数: {{ seed[0].limited === 0 ? '不限': seed[0].totalCount }} 次 </div>
                <div class="used" v-if="packageManage != 1">已使用次数: {{ seed[0].usedCount }} 次</div>
                <div class="residue" v-if="packageManage != 1">剩余次数:  {{ seed[0].balanceCount }} 次</div>
            </div>
            <div class="item" v-for="(item, i) in tableData" :key="i">
                <div class="name">{{ i == 0 ? '服务:':''}}</div>
                <div class="service">{{ packageManage == 1 ? item.name : item.serviceTypeName }}</div>
                <div class="free" v-if="packageManage == 1">种植单位免费次数: {{ item.limited === 0 ? '不限': item.count }} 次</div>
                <div class="free" v-if="packageManage != 1">种植单位免费次数: {{ item.limited === 0 ? '不限': item.totalCount }} 次</div>
                <div class="used" v-if="packageManage != 1">已使用次数: {{ item.usedCount }} 次</div>
                <div class="residue" v-if="packageManage != 1">剩余次数: {{ item.balanceCount }} 次</div>
            </div>
        </div>

        <div v-else>
            <div class="item">
                <div class="name" >种子:</div>
                <div class="service">全部种子</div>
                <div class="free" v-if="packageManage == 1">种植单位免费次数: 0 次 </div>
                <div class="free" v-if="packageManage != 1">种植单位免费次数: 0 次</div>
                <div class="used" v-if="packageManage != 1">已使用次数: 0 次</div>
                <div class="residue" v-if="packageManage != 1">剩余次数: -- 次</div>
            </div>
            <div class="item" v-for="(item, i) in empty" :key="i">
                <div class="name">{{ i == 0 ? '服务:':''}}</div>
                <div class="service">{{ item }}</div>
                <div class="free" v-if="packageManage == 1">种植单位免费次数: 0 次</div>
                <div class="free" v-if="packageManage != 1">种植单位免费次数: 0 次</div>
                <div class="used" v-if="packageManage != 1">已使用次数: 0 次</div>
                <div class="residue" v-if="packageManage != 1">剩余次数: -- 次</div>
            </div>
        </div>

        <!-- <div class="item">
            <div class="name"></div>
            <div class="service">浇水</div>
            <div class="free">种植单位免费次数: 不限次</div>
            <div class="used" v-if="packageManage != 1">已使用次数: 1次</div>
            <div class="residue" v-if="packageManage != 1">剩余次数: 0</div>
        </div>
         <div class="item">
            <div class="name"></div>
            <div class="service">除草</div>
            <div class="free">种植单位免费次数: 不限次</div>
            <div class="used" v-if="packageManage != 1">已使用次数: 1次</div>
            <div class="residue" v-if="packageManage != 1">剩余次数: 0</div>
        </div>
        <div class="item">
            <div class="name"></div>
            <div class="service">施肥</div>
            <div class="free">种植单位免费次数: 不限次</div>
            <div class="used" v-if="packageManage != 1">已使用次数: 1次</div>
            <div class="residue" v-if="packageManage != 1">剩余次数: 0</div>
        </div>
        <div class="item">
            <div class="name"></div>
            <div class="service">收获</div>
            <div class="free">种植单位免费次数: 不限次</div>
            <div class="used" v-if="packageManage != 1">已使用次数: 1次</div>
            <div class="residue" v-if="packageManage != 1">剩余次数: 0</div>
        </div> -->
    </el-dialog>
</template>

<script>
import { URL } from "../../../../config/url.supplier";

export default {
    name: "packageService",
    props: {
		dialogVisible:{
			type: Boolean,
			default: false
		},
		currentRow:{
			type:Object,
			default: () => {}
		},
        packageManage: {
            type: Number,
            default: 0
        }
    },
    data() {
        return {
            visible: false,
            loading:false,
            index: null,
            tableData: [],
            seed: [],
            empty:['播种', '浇水', '除草', '施肥', '帮我收获']
        }
    },
    methods: {
        getList() {
            if (this.packageManage == 1) {
                this.axios.get(`${URL.farm.farmRentpackagerelateserviceListByPackageId}${this.currentRow.id}`).then(res=>{
                    if(res.code === '0'){
                        this.tableData = res.data.filter((item) => item.type != 1);
                        this.seed = res.data.filter((item) => item.type == 1);
                        console.log(this.seed);
                    }else{
                        this.$message.error(res.msg);
                    }
                })
            } else {
                this.axios.post(URL.farm.farmServicetypeblanceList, { landRentId: this.currentRow.id }).then(res=>{
                    if(res.code === '0'){
                        this.tableData = res.data.filter((item) => item.type != 1);
                        this.seed = res.data.filter((item) => item.type == 1);
                        console.log(this.seed,1312);
                    }else{
                        this.$message.error(res.msg);
                    }
                })
            }
        },
        close() {
            this.$emit('closeCheck')
        }

    },
    watch:{
        currentRow: {
            handler: function(oldV, newV) {
                // console.log(oldV, '333');

            },
            // deep: true
        },
        dialogVisible: function(val) {
            this.visible = val;
            this.getList();
        }

    },

    mounted() {

    }
}
</script>

<style lang="less" scoped>
.item {
    display: flex;
    margin-bottom: 20px;

    .name {
        width: 50px;
    }

    .service {
        width: 100px;
    }

    .free {
        width: 200px;
    }

    .used {
        width: 120px;
    }

    .residue {
        width: 100px;
    }

}
.empty {
    width: 100%;
    height: 280px;
    text-align: center;
    line-height: 280px;
    color: #3f3b3b;
}
</style>
